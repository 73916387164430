exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contacts-index-js": () => import("./../../../src/pages/contacts/index.js" /* webpackChunkName: "component---src-pages-contacts-index-js" */),
  "component---src-pages-credits-index-js": () => import("./../../../src/pages/credits/index.js" /* webpackChunkName: "component---src-pages-credits-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lectures-index-js": () => import("./../../../src/pages/lectures/index.js" /* webpackChunkName: "component---src-pages-lectures-index-js" */),
  "component---src-pages-lectures-markdown-remark-parent-file-name-js": () => import("./../../../src/pages/lectures/{MarkdownRemark.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-lectures-markdown-remark-parent-file-name-js" */),
  "component---src-pages-performances-index-js": () => import("./../../../src/pages/performances/index.js" /* webpackChunkName: "component---src-pages-performances-index-js" */),
  "component---src-pages-performances-markdown-remark-parent-file-name-js": () => import("./../../../src/pages/performances/{MarkdownRemark.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-performances-markdown-remark-parent-file-name-js" */),
  "component---src-pages-workshops-markdown-remark-parent-file-name-js": () => import("./../../../src/pages/workshops/{MarkdownRemark.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-workshops-markdown-remark-parent-file-name-js" */)
}

